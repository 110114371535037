import Primary from './Primary';
import Secondary from './Secondary';
import Tertiary from './Tertiary';

const Button = Object.assign(Primary, {
  Primary,
  Secondary,
  Tertiary,
});

export default Button;
