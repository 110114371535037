import {
  defaultToast,
  errorToast as error,
  infoToast as info,
  successToast as success,
  warningToast as warning,
} from './ToastFunctions';

export { default as ToastContainer } from './ToastContainer.component';

export const toast = Object.assign(defaultToast, {
  defaultToast,
  error,
  info,
  success,
  warning,
});
