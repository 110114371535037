import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { ExclusiveSelectionGroup } from '../ExclusiveSelectionGroup';

import { KeyLabel, Props } from './TripleChoiceButton.types';

export const yesNoUnknownAnswers: KeyLabel[] = [
  { key: 'Yes', label: 'oui' },
  { key: 'No', label: 'non' },
  { key: 'Unknown', label: 'info inconnue' },
];

const TripleChoiceButton = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => (
  <ExclusiveSelectionGroup<TFieldValues, KeyLabel, string | undefined, TName>
    {...props}
    values={yesNoUnknownAnswers}
    getSelectedFormValue={element => element?.key}
    getSelectedValue={value => {
      return (
        yesNoUnknownAnswers.find(_ => _.key === value) ?? { key: 'Unknown', label: 'info inconnue' }
      );
    }}
    getStringValue={({ label }) => label}
    getKey={({ key }) => key}
  />
);

export default TripleChoiceButton;
