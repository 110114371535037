import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';

import { TextArea } from '..';
import { pluralFormat } from '../../Utils/pluralFormat';

import styles from './TextAreaWithLightTitle.module.scss';
import { Props } from './TextAreaWithLightTitle.types';

function TextAreaWithLightTitle<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  title,
  placeHolder,
  control,
  name,
  valueLength,
  containerClassName,
  textAreaClassName,
  maxLength,
  counterClassName,
  ...textAreaProps
}: Props<TFieldValues, TName>) {
  return (
    <WithLightTitle
      className={containerClassName}
      title={title}
      rightTitleComponent={
        <div className={classnames(styles.textLength, counterClassName)}>
          {maxLength
            ? `${valueLength}/${maxLength}${pluralFormat(maxLength ?? 0, 'caractère', true)}`
            : pluralFormat(valueLength ?? 0, 'caractère')}
        </div>
      }
    >
      <TextArea
        {...textAreaProps}
        control={control}
        name={name}
        className={classnames(styles.textAreaWidth, textAreaClassName)}
        placeholder={placeHolder}
        maxLength={maxLength}
      />
    </WithLightTitle>
  );
}

export default TextAreaWithLightTitle;
