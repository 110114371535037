import classnames from 'classnames';
import React from 'react';

import Base from '../Base';
import Content from '../Content';
import { ButtonProps, Sizeable } from '../shared';

import styles from './Tertiary.module.scss';

const BaseTertiary = ({
  children,
  disabled,
  className,
  text,
  leftIcon,
  rightIcon,
  darkMode,
  ...props
}: ButtonProps) => (
  <Base
    className={classnames(
      { [styles.lightModeContainer]: !darkMode },
      { [styles.disabled]: disabled },
      { [styles.darkModeContainer]: darkMode },
      className
    )}
    disabled={disabled}
    {...props}
  >
    {children || <Content leftIcon={leftIcon} text={text} rightIcon={rightIcon} />}
  </Base>
);

const Tertiary = Sizeable(BaseTertiary);

export default Tertiary;
