const styles = {
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#d7d7d7',
    borderStyle: 'solid',
    backgroundColor: 'white',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  focusedStyle: {
    borderColor: '#2175d9',
  },
  activeStyle: {
    borderColor: '#2175d9',
  },
  acceptStyle: {
    borderColor: '#2175d9',
  },
  rejectStyle: {
    borderColor: '#f24747',
    backgroundColor: '#fce8e6',
  },
  disabledStyle: {
    pointerEvent: 'none',
    backgroundColor: '#d7d7d7',
  },
  mobileStyle: {
    padding: '2rem',
  },
};

export default styles;
