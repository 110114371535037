/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import * as z from 'zod';

export interface UseFormWithZodResolverProps<Schema extends z.ZodSchema<any>>
  extends Omit<UseFormProps<z.infer<Schema>>, 'resolver'> {
  schema: Schema;
}

export function useFormWithZodResolver<Schema extends z.ZodSchema<any>>({
  schema,
  ...formProps
}: UseFormWithZodResolverProps<Schema>) {
  const errorMap: z.ZodErrorMap = (error, ctx) => {
    /**
     * This is where you override the various default error codes
     *
     * Note: zod only applies refinement if the schema is valid, so if a string field
     * receives an undefined, it will throw only the ZodIssueCode.invalid_type
     */
    switch (error.code) {
      case z.ZodIssueCode.too_small:
        return {
          message: `${error.minimum} caractères minimum`,
        };
      case z.ZodIssueCode.too_big:
        return {
          message: `${error.maximum} caractères maximum`,
        };
      case z.ZodIssueCode.invalid_string:
        return {
          message: "Ce champ n'est pas valide",
        };
      case z.ZodIssueCode.invalid_type:
        if (error.received === 'undefined') {
          return {
            message: 'Ce champ est requis',
          };
        }
        break;
    }

    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed(`Unmanaged ZodIssueCode (code: ${error.code})`);
      console.error('Error:', error);
      console.error('Context:', ctx);
      console.groupEnd();
    }

    // fall back to default message
    return { message: ctx.defaultError };
  };

  return useForm<z.infer<Schema>>({
    resolver: zodResolver(schema, { errorMap }),
    mode: 'onTouched',
    ...formProps,
  });
}
