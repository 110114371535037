import { useMediaQuery } from '@mui/material';
import { ClosePop } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useImperativeHandle } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import Button from '../../Buttons';
import BaseModal from '../BaseModal';

import styles from './Modal.module.scss';
import { Props } from './Modal.types';

const Modal = React.forwardRef<PopupActions, Props>(
  (
    {
      title,
      titleClassName,
      children,
      footerActionsRight,
      footerActionsLeft,
      icon,
      iconClassName,
      trigger,
      hideCloseAction,
      subtitle,
      containerClassName,
      ...rest
    }: Props,
    ref
  ) => {
    const innerRef = React.useRef<PopupActions>(null);

    useImperativeHandle<PopupActions | null, PopupActions | null>(ref, () => ({
      close: () => innerRef.current?.close(),
      open: () => innerRef.current?.open(),
      toggle: () => innerRef.current?.toggle(),
    }));
    const isMobile = useMediaQuery('(max-device-width: 768px)');

    return (
      <BaseModal trigger={trigger} ref={innerRef} {...rest}>
        <div
          className={classnames(
            styles.container,
            { [styles.mobileContainer]: isMobile },
            containerClassName
          )}
        >
          <div className={classnames(styles.icon, iconClassName)}>{icon}</div>
          {!hideCloseAction && (
            <div className={classnames(styles.cross, { [styles.mobileCross]: isMobile })}>
              {
                <Button.Tertiary
                  onClick={() => {
                    innerRef.current?.close();
                  }}
                  style={{
                    padding: '0.5rem',
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                    width: '2.5rem',
                    minWidth: '0px',
                  }}
                >
                  <ClosePop />
                </Button.Tertiary>
              }
            </div>
          )}
          <div className={styles.header}>
            <div className={classnames(styles.title, titleClassName)}>{title}</div>
          </div>
          <div className={styles.subtitle}>{subtitle ?? <></>}</div>
          <div className={styles.content}>{children}</div>
          {(footerActionsLeft !== undefined || footerActionsRight !== undefined) && (
            <div className={styles.footer}>
              <div className={styles.sideFooter}>
                {footerActionsLeft?.map((footerAction, idx) => (
                  <div key={`actionFooter${idx}`} className={styles.footerButton}>
                    {footerAction}
                  </div>
                ))}
              </div>
              <div className={styles.sideFooter}>
                {footerActionsRight?.map((footerAction, idx) => (
                  <div key={`actionFooter${idx}`} className={styles.footerButton}>
                    {footerAction}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </BaseModal>
    );
  }
);

export default Modal;
