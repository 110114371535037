import { WhiteCircleBottomArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import Badge from '../Badge';
import RawDropdown from '../RawDropdown';

import styles from './BadgeDropdown.module.scss';
import { Props } from './BadgeDropdown.types';

const BadgeDropdown = <T,>(props: Props<T>) => {
  const { selectedItem, keyValueExtractor } = props;
  return (
    <RawDropdown
      trigger={
        <div>
          <Badge
            value={selectedItem ? keyValueExtractor(selectedItem).value : 'N/A'}
            rightIcon={<WhiteCircleBottomArrow />}
            className={
              selectedItem
                ? classnames(styles.badge, keyValueExtractor(selectedItem)?.className)
                : styles.badge
            }
          />
        </div>
      }
      position="bottom right"
      {...props}
    />
  );
};

export default BadgeDropdown;
