import React from 'react';

import { PopupMenu } from '../Popups';

import { RawDropdownProps } from './RawDropdown.types';

const RawDropdown = <T,>({
  placeholder,
  canBeReset,
  items,
  keyValueExtractor,
  onSelectItem,
  selectedItem,
  ...props
}: RawDropdownProps<T>) => {
  return (
    <PopupMenu
      selectedIds={selectedItem ? [keyValueExtractor(selectedItem).key] : ['canBeResetItem']}
      arrow={false}
      animated
      {...props}
    >
      {canBeReset && (
        <PopupMenu.Item
          text={placeholder ?? ''}
          onClick={() => onSelectItem(undefined)}
          id="canBeResetItem"
        />
      )}
      {items.map(item => (
        <PopupMenu.Item
          key={keyValueExtractor(item).key}
          id={keyValueExtractor(item).key}
          text={keyValueExtractor(item).value}
          icon={keyValueExtractor(item).icon}
          onClick={() => onSelectItem(item)}
        />
      ))}
    </PopupMenu>
  );
};

export default RawDropdown;
