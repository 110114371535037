import { DropDown as DropDownComponent } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import styles from './DropDown.module.scss';
import { Props } from './DropDown.types';

function DropDown<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>, T>({
  name,
  control,
  defaultValue,
  ...dropDownProps
}: Props<TFieldValues, TName, T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div className={dropDownProps.containerClassName}>
      <DropDownComponent
        {...dropDownProps}
        {...field}
        selectedItem={field.value}
        onSelectItem={(value: T) => field.onChange(value)}
        className={classnames(dropDownProps.className, {
          [styles.error]: error !== undefined,
        })}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default DropDown;
