import classNames from 'classnames';
import React from 'react';

import Button from '../../Buttons';

import styles from './InclusiveSelectionGroup.module.scss';
import { Props } from './InclusiveSelectionGroup.types';

function InclusiveSelectionGroup<T>({
  values,
  selected,
  buttonClassname,
  setSelected,
  getKey,
  getStringValue,
}: Props<T>) {
  return (
    <div className={styles.container}>
      {values.map(value => {
        const id = getKey(value);
        const label = getStringValue(value);

        return (
          <Button.Secondary.Small
            key={id}
            onClick={() => {
              const filteredSelected = selected.filter(el => getKey(el) !== id);

              if (filteredSelected.length === selected.length) {
                setSelected([...filteredSelected, value]);
              } else {
                setSelected(filteredSelected);
              }
            }}
            className={classNames(
              styles.button,
              {
                [styles.selected]: selected.includes(value),
              },
              buttonClassname
            )}
          >
            {label}
          </Button.Secondary.Small>
        );
      })}
    </div>
  );
}

export default InclusiveSelectionGroup;
