import classnames from 'classnames';
import React from 'react';

import styles from './Badge.module.scss';
import { Props } from './Badge.types';

const Badge = ({
  className,
  value,
  leftIcon,
  rightIcon,
  onLeftIconClick,
  onRightIconClick,
  onClick,
}: Props) => (
  <div className={classnames(styles.container, className)} onClick={onClick}>
    {leftIcon && (
      <div className={styles.leftIcon} onClick={onLeftIconClick}>
        {leftIcon}
      </div>
    )}
    <div className={styles.value}>{value}</div>
    {rightIcon && (
      <div className={styles.rightIcon} onClick={onRightIconClick}>
        {rightIcon}
      </div>
    )}
  </div>
);

export default Badge;
