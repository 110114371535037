import { WhiteCross } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { ToastContainer as PackageToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Props } from './ToastContainer.types';

const ToastContainer = ({ closeButton = true, position }: Props) => {
  return (
    <PackageToastContainer
      autoClose={3000}
      closeOnClick
      closeButton={closeButton ? <WhiteCross /> : false}
      draggable={false}
      hideProgressBar={true}
      position={position}
      style={{ width: '30.5rem' }}
    />
  );
};

export default ToastContainer;
