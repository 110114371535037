import { AutoSuggest as AutoSuggestComponent } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import { Props } from './AutoSuggest.types';

function AutoSuggest<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>, T>({
  name,
  control,
  defaultValue,
  containerClassName,
  ...autoSuggestProps
}: Props<TFieldValues, TName, T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <div className={containerClassName}>
      <AutoSuggestComponent {...autoSuggestProps} {...field} isError={!!error} />
      <ErrorMessage error={error} />
    </div>
  );
}

export default AutoSuggest;
