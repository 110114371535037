import { ButtonProps } from '../shared';

export const BUTTON_STATUS = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
  PRISTINE: 'PRISTINE',
  RETRY: 'RETRY',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BUTTON_STATUS = (typeof BUTTON_STATUS)[keyof typeof BUTTON_STATUS];

export interface Props extends ButtonProps {
  fetchStatus: BUTTON_STATUS;
  title: string;
  stayFulfilled?: boolean;
  errorTitle?: string;
  errorMessage?: string;
  secondary?: boolean;
  onBeforeSuccessAnimation?: () => void;
  onBeforeErrorAnimation?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}
