import { TextField } from '@mui/material';
import React from 'react';

import styles from './TextInput.module.scss';
import { Props } from './TextInput.types';

const TextInput = (props: Props) => {
  return <TextField className={styles.container} {...props} />;
};

export default TextInput;
