import {
  BigHisto,
  MediumHisto,
  Pencil,
  PlusWithWhiteBack,
  SmallHisto,
  ThreeDots,
  UpWhiteArrow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import Button from '../../Buttons';
import PopupMenu from '../../Popups/PopupMenu';
import PopupMenuItem from '../../Popups/PopupMenu/PopupMenuItem';
import { useOutsideAlerter } from '../../utils';

import styles from './OrganizationChartItem.module.scss';
import { OrganizationChartItemSize, Props } from './OrganizationChartItem.types';

const OrganizationChartItem = ({
  title,
  subtitle,
  className,
  size,
  onTextChange,
  onSizeChange,
  addChild,
  addParent,
  deleteItem,
  isRoot,
  isDisplayMode,
}: Props) => {
  const sizeIconMapping = {
    SMALL: <SmallHisto />,
    MEDIUM: <MediumHisto />,
    BIG: <BigHisto />,
  };

  const [isSelected, setIsSelected] = useState(false);
  const [isOpenAction, setIsOpenAction] = useState(false);
  const [isOpenItemSize, setIsOpenItemSize] = useState(false);
  const itemRef = useRef(null);
  const popupRef = useRef(null);
  useOutsideAlerter({
    ref: itemRef,
    secondRef: popupRef,
    action: () => {
      if (isSelected) {
        setIsOpenAction(false);
        setIsOpenItemSize(false);
        setIsSelected(false);
      }
    },
  });
  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.itemContainer, className, {
          [styles.secondaryItemContainer]: size === OrganizationChartItemSize.MEDIUM,
          [styles.tertiaryItemContainer]: size === OrganizationChartItemSize.SMALL,
          [styles.selected]: isSelected,
          [styles.secondarySelected]: isSelected && size === OrganizationChartItemSize.MEDIUM,
          [styles.tertiarySelected]: isSelected && size === OrganizationChartItemSize.SMALL,
        })}
        onClick={() => (!isSelected && !isDisplayMode ? setIsSelected(true) : undefined)}
        ref={itemRef}
        style={{
          width:
            (title.length + 1) * 8 >= ((subtitle?.length ?? 0) + 1) * 6
              ? (title.length + 1) * 8 + 32 + 'px'
              : ((subtitle?.length ?? 0) + 1) * 6 + 32 + 'px',
        }}
      >
        <div
          className={classnames(styles.title, {
            [styles.secondaryTitle]: size !== OrganizationChartItemSize.BIG,
          })}
        >
          {isSelected ? (
            <input
              className={classnames(styles.titleTextInput, {
                [styles.secondaryTitleTextInput]: size !== OrganizationChartItemSize.BIG,
              })}
              style={{ width: (title.length + 1) * 8 + 'px' }}
              type="text"
              value={title}
              onChange={e => {
                onTextChange({ newText: e.currentTarget.value, isTitle: true });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsSelected(false);
                }
              }}
            />
          ) : (
            title
          )}
        </div>
        <div
          className={classnames(styles.subtitle, {
            [styles.secondarySubTitle]: size !== OrganizationChartItemSize.BIG,
          })}
        >
          {isSelected ? (
            <input
              className={classnames(styles.subtitleTextInput, {
                [styles.secondarySubTitle]: size !== OrganizationChartItemSize.BIG,
              })}
              style={{ width: ((subtitle?.length ?? 0) + 1) * 6 + 'px' }}
              type="text"
              value={subtitle}
              onChange={e => {
                onTextChange({ newText: e.currentTarget.value, isTitle: false });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsSelected(false);
                }
              }}
            />
          ) : (
            subtitle
          )}
        </div>
        {isSelected && (
          <div>
            <PlusWithWhiteBack
              role="plusButton"
              onClick={() => {
                addChild();
                setIsSelected(false);
              }}
              ref={itemRef}
              className={styles.plusButton}
            />
          </div>
        )}
        {isSelected && (
          <div className={styles.toolBar}>
            <PopupMenu
              width={40}
              position="top center"
              onOpen={() => setIsOpenItemSize(true)}
              onClose={() => {
                setIsSelected(false);
                setIsOpenItemSize(false);
              }}
              trigger={
                <Button.Tertiary
                  className={classnames(styles.actionButtonContainer, {
                    [styles.activeTrigger]: isOpenItemSize,
                  })}
                >
                  <SmallHisto className={styles.sizeButtonContainer} />
                </Button.Tertiary>
              }
            >
              <div ref={popupRef}>
                {Object.values(OrganizationChartItemSize).map(
                  (sizeChoice: OrganizationChartItemSize) => {
                    return (
                      <Button.Tertiary
                        onClick={() => {
                          onSizeChange({ newSize: sizeChoice });
                        }}
                        className={classnames(
                          styles.actionButtonContainer,
                          styles.sizeButtonContainer,
                          {
                            [styles.selectedSize]: size === sizeChoice,
                          }
                        )}
                      >
                        {sizeIconMapping[sizeChoice]}
                      </Button.Tertiary>
                    );
                  }
                )}
              </div>
            </PopupMenu>
            <div className={styles.verticalSeparator} />
            {!isRoot && (
              <PopupMenu
                width={250}
                position="top left"
                onOpen={() => setIsOpenAction(true)}
                onClose={() => {
                  setIsSelected(false);
                  setIsOpenAction(false);
                }}
                trigger={
                  <Button.Tertiary
                    className={classnames(styles.actionButtonContainer, {
                      [styles.activeTrigger]: isOpenAction,
                    })}
                  >
                    <ThreeDots className={styles.icon} role="actionButton" />
                  </Button.Tertiary>
                }
              >
                <div ref={popupRef}>
                  <PopupMenuItem
                    className={styles.popupItem}
                    onClick={() => {
                      deleteItem();
                    }}
                    text="supprimer l'élément et ses enfants"
                    icon={<Pencil />}
                  />
                </div>
              </PopupMenu>
            )}
          </div>
        )}
        {isSelected && isRoot && (
          <UpWhiteArrow
            role="addParentButton"
            onClick={addParent}
            ref={itemRef}
            className={classnames(styles.addParentButton, styles.plusButton)}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationChartItem;
