import { CloseOld, Loupe } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import Button from '../../Buttons';
import { TextInput } from '../TextInput';

import styles from './TypeAndEnter.module.scss';
import { Props } from './TypeAndEnter.types';

function TypeAndEnter({
  textInputClassName,
  textInputContainerClassName,
  selectedValue,
  setSelectedValue,
  placeholder,
}: Props) {
  const [textInput, setTextInput] = useState(selectedValue);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setSelectedValue(textInput);
      }
    },
    [setSelectedValue, textInput]
  );

  return (
    <div className={styles.container}>
      <TextInput
        containerClassName={classNames(styles.textInputContainer, textInputContainerClassName)}
        className={classNames(styles.textInput, textInputClassName)}
        leftIcon={<Loupe />}
        placeholder={placeholder}
        value={textInput}
        onChange={event => {
          const value = (event.target as HTMLInputElement).value;
          setTextInput(value);
        }}
        rightIcon={textInput.length ? <CloseOld /> : undefined}
        onRightIconClick={() => {
          setTextInput('');
          setSelectedValue('');
        }}
        onKeyDown={handleKeyDown}
      />
      <Button.Tertiary
        className={styles.button}
        onClick={() => {
          if (selectedValue === textInput) {
            setTextInput('');
            setSelectedValue('');
          } else {
            setSelectedValue(textInput);
          }
        }}
        text={selectedValue === textInput ? 'Réinitialiser' : 'Rechercher'}
      />
    </div>
  );
}

export default TypeAndEnter;
