import classnames from 'classnames';
import React from 'react';

import Button from '../../Buttons';

import styles from './ExclusiveSelectionGroup.module.scss';
import { Props } from './ExclusiveSelectionGroup.types';

function ExclusiveSelectionGroup<T>({
  values,
  className,
  buttonClassName,
  selectedClassName,
  selected,
  setSelected,
  canBeReset,
  getKey,
  getStringValue,
  disabled,
  dense,
  large,
  verticalDisplay,
  leftIcons,
}: Props<T>) {
  return (
    <div
      className={classnames(styles.container, className, {
        [styles.verticalContainer]: verticalDisplay,
      })}
    >
      {values.map((value, index) => {
        const id = getKey(value);
        const label = getStringValue(value);

        if (selected && id === getKey(selected))
          return (
            <Button.Primary.Small
              key={id}
              disabled={disabled}
              leftIcon={leftIcons ? leftIcons[index] : undefined}
              text={label}
              className={classnames(styles.button, selectedClassName, buttonClassName, {
                [styles.dense]: dense,
                [styles.large]: large,
              })}
              onClick={() => {
                if (canBeReset) {
                  setSelected(undefined);
                }
              }}
            >
              {label}
            </Button.Primary.Small>
          );

        return (
          <Button.Secondary.Small
            key={id}
            disabled={disabled}
            leftIcon={leftIcons ? leftIcons[index] : undefined}
            text={label}
            onClick={() => setSelected(value)}
            className={classnames(styles.unselectedButton, buttonClassName, {
              [styles.disabledSecondary]: disabled,
              [styles.dense]: dense,
              [styles.large]: large,
            })}
          >
            {label}
          </Button.Secondary.Small>
        );
      })}
    </div>
  );
}

export default ExclusiveSelectionGroup;
