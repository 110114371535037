import React, { useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import styles from './SignatureDrawer.module.scss';
import { Props } from './SignatureDrawer.types';
const SignatureDrawer = ({ size, onEnd, onClear, disabled }: Props) => {
  const ref = useRef<SignatureCanvas>(null);
  useEffect(() => {
    if (ref.current) {
      if (disabled) {
        ref.current.off();
      } else {
        ref.current.on();
      }
    }
  }, [disabled]);
  return (
    <div className={styles.container}>
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          className: styles.canvas,
          width: size?.width ?? 672,
          height: size?.height ?? 210,
        }}
        ref={ref}
        onEnd={() => onEnd?.(ref.current?.toDataURL('sign/png'))}
      />
      <div className={styles.padHeader}>tracez votre signature</div>
      <div
        className={styles.clearButton}
        onClick={() => {
          ref?.current?.clear();
          onClear?.();
        }}
      >
        effacer
      </div>
    </div>
  );
};

export default SignatureDrawer;
