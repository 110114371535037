import { Props, OrganizationChartItem as OrgItem } from 'OrganizationChart/OrganizationChart.types';
import React from 'react';

import OrganizationChartItem from '../OrganizationChartItem';
import { OrganizationChartItemSize } from '../OrganizationChartItem/OrganizationChartItem.types';
import TreeNode from '../Tree/TreeNode';
import { deleteElementInTree, findMaxIdInTree, UpdateElementInData } from '../utils';

const OrganizationChartChildren = ({
  data,
  updateData,
  fullTree,
  titlePlaceholder,
  subtitlePlaceholder,
  isDisplayMode,
}: Props) => {
  return (
    <>
      {data.map(dataItem => {
        return (
          <TreeNode
            key={dataItem.id}
            label={
              <OrganizationChartItem
                isDisplayMode={isDisplayMode}
                size={dataItem.size ?? OrganizationChartItemSize.BIG}
                isRoot={dataItem.id === fullTree?.[0].id}
                title={dataItem.title}
                id={dataItem.id}
                subtitle={dataItem.subtitle}
                className={dataItem.className}
                onTextChange={({ newText, isTitle }) => {
                  const newData = UpdateElementInData({
                    id: dataItem.id,
                    data: fullTree ?? [],
                    property: isTitle ? 'title' : 'subtitle',
                    value: newText,
                  }) as OrgItem[];
                  updateData(newData);
                }}
                onSizeChange={({ newSize }) => {
                  const newData = UpdateElementInData({
                    id: dataItem.id,
                    data: fullTree ?? [],
                    property: 'size',
                    value: newSize,
                  }) as OrgItem[];
                  updateData(newData);
                }}
                addChild={() => {
                  const maxId = findMaxIdInTree(fullTree ?? []);
                  const newData = UpdateElementInData({
                    id: dataItem.id,
                    data: fullTree ?? [],
                    property: 'children',
                    value: [
                      ...(dataItem.children ?? []),
                      {
                        id: maxId + 1,
                        title: titlePlaceholder,
                        subtitle: subtitlePlaceholder,
                        size: dataItem.size,
                      },
                    ],
                  });
                  updateData(newData);
                }}
                addParent={() => {
                  const maxId = findMaxIdInTree(fullTree ?? []);
                  const newData = [
                    {
                      id: maxId + 1,
                      title: titlePlaceholder,
                      subtitle: subtitlePlaceholder,
                      children: fullTree,
                      size: OrganizationChartItemSize.BIG,
                    },
                  ];
                  updateData(newData);
                }}
                deleteItem={() => {
                  const newData = deleteElementInTree({ id: dataItem.id, data: fullTree ?? [] });
                  updateData(newData);
                }}
              />
            }
          >
            {dataItem.children !== undefined && dataItem.children.length > 0 ? (
              <OrganizationChartChildren
                isDisplayMode={isDisplayMode}
                data={dataItem.children}
                updateData={updateData}
                fullTree={fullTree}
                titlePlaceholder={titlePlaceholder}
                subtitlePlaceholder={subtitlePlaceholder}
              />
            ) : undefined}
          </TreeNode>
        );
      })}
    </>
  );
};

export default OrganizationChartChildren;
