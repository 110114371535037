import classnames from 'classnames';
import React from 'react';

import styles from './SegmentedControl.module.scss';
import { Props } from './SegmentedControl.types';

function SegmentedControl<T>({
  selected,
  controls,
  onSelectionChange,
  getValue,
  className,
  labelClassName,
  selectedBackgroundClassName,
}: Props<T>) {
  const width = `${100 / controls.length}%`;

  return (
    <div className={classnames(styles.container, className)}>
      {controls.map(control => (
        <label
          key={`${control}`}
          className={classnames(styles.control, labelClassName, {
            [styles.selected]: selected === control,
          })}
          style={{
            width,
          }}
        >
          <span className={styles.text}>{getValue(control)}</span>
          <input
            className={styles.input}
            type="radio"
            name="radioBtn"
            onChange={() => {
              onSelectionChange(control);
            }}
          />
        </label>
      ))}
      <div
        className={classnames(styles.selectedBackground, selectedBackgroundClassName)}
        style={{
          width,
          transform: `translateX(${100 * controls.findIndex(control => control === selected)}%)`,
        }}
      >
        <div />
      </div>
    </div>
  );
}

export default SegmentedControl;
