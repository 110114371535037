import classnames from 'classnames';
import React from 'react';

import styles from './TimePicker.module.scss';
import { Props } from './TimePicker.types';

const TimePicker = React.forwardRef<HTMLInputElement, Props>(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={classnames(styles.timePicker, className)}
      type={'time'}
      {...props}
    />
  );
});

export default TimePicker;
