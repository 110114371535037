import {
  TextArea as TextAreaComponent,
  TextAreaProps,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import styles from './TextArea.module.scss';
import { Props } from './TextArea.types';

function TextArea<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  defaultValue,
  className,
  ...textAreaProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <TextAreaComponent
        className={classnames(
          styles.container,
          {
            [styles.disabled]: textAreaProps.disabled,
            [styles.error]: error !== undefined,
          },
          className
        )}
        {...textAreaProps}
        {...field}
        value={field.value as TextAreaProps['value']}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default TextArea;
