import React from 'react';

import { AppData, apps } from '../../AppsDetailsValues/AppsDetailsValues';
import { getAppEcosystem } from '../../getAppEcosystem';
import { PopupActions } from '../../Modals';
import Popup from '../../Popups/Popup';

import AppButton from './AppButton';
import styles from './RandstadAppsPopup.module.scss';
import { Props } from './RandstadAppsPopup.types';

const RandstadAppsPopup = React.forwardRef<PopupActions, Props>(
  ({ onItemClick, isProd, brandCode, ...popupProps }, ref) => {
    let activeApps: AppData[] = apps;
    if (brandCode) {
      activeApps = apps.filter(app => getAppEcosystem({ brand: brandCode }).includes(app.name));
    }
    return (
      <Popup ref={ref} contentStyle={{ padding: '0.5rem' }} {...popupProps}>
        <div className={styles.container}>
          {activeApps.map(({ icon, name, link }) => (
            <AppButton
              key={name}
              icon={isProd ? icon.prod : icon.uat}
              name={name}
              link={isProd ? link.prod : link.uat}
              onClick={onItemClick}
            />
          ))}
        </div>
      </Popup>
    );
  }
);

export default RandstadAppsPopup;
