import React from 'react';

import { Props } from './OrganizationChart.types';
import OrganizationChartChildren from './OrganizationChartChildren';
import Tree from './Tree';

const OrganizationChart = ({
  data,
  updateData,
  titlePlaceholder,
  subtitlePlaceholder,
  isDisplayMode,
}: Props) => (
  <Tree
    lineWidth="1px"
    lineHeight="40px"
    lineColor="#8a8a8a"
    lineBorderRadius="0px"
    label={
      <OrganizationChartChildren
        data={data}
        updateData={updateData}
        fullTree={data}
        titlePlaceholder={titlePlaceholder}
        subtitlePlaceholder={subtitlePlaceholder}
        isDisplayMode={isDisplayMode}
      />
    }
  />
);

export default OrganizationChart;
