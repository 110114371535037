import { useMediaQuery } from '@mui/material';
import {
  WarningRed,
  TickCircleGreen,
  Text,
  Image,
  Trashcan,
  Refresh,
  EyeBig,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { UploadStatus } from '../FileDropzone.types';

import styles from './FileDropzoneItem.module.scss';
import { Props } from './FileDropzoneItem.types';

const FileDropzoneItem = ({ item, deleteItem, onClick }: Props) => {
  const isMobile = useMediaQuery('(max-device-width: 768px)');
  const file = item.file;
  const statusIcon = () => {
    switch (item.status) {
      case UploadStatus.InProgress:
        return <Refresh className={styles.loading} />;
      case UploadStatus.Done:
        return <TickCircleGreen />;
      case UploadStatus.Failed:
        return <WarningRed />;
      case UploadStatus.None:
        return (
          <button className={styles.visualize} onClick={onClick}>
            <EyeBig />
          </button>
        );
      default:
        return undefined;
    }
  };
  return (
    <div className={styles.container} key={file.name}>
      <div className={isMobile ? styles.mobileSubContainer : styles.subContainer}>
        <div className={styles.leftPart}>
          <div className={styles.leftIconContainer}>
            {item && file.type.includes('image') ? <Image /> : <Text />}
          </div>
          <div className={styles.text}>
            <div className={isMobile ? styles.mobileTitle : styles.title} onClick={onClick}>
              {file.name}
            </div>
            <div className={styles.subtitle}>{item.subtitle}</div>
          </div>
        </div>
        <div className={styles.status}>{statusIcon()}</div>
        {item.id && (
          <button className={styles.delete} onClick={deleteItem}>
            <Trashcan />
          </button>
        )}
      </div>
    </div>
  );
};

export default FileDropzoneItem;
