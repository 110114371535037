import Fade from './Fade';
import HorizontalUnroll from './HorizontalUnroll';
import Unroll from './Unroll';

const Animation = {
  Unroll: Object.assign(Unroll, {
    Horizontal: HorizontalUnroll,
  }),
  Fade,
};

export default Animation;
