import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fr';
import { useMediaQuery } from '@mui/material';
import {
  DownArrow,
  FlecheLeft,
  FlecheRight,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import './DatePicker.scss';
import './DatePickerMobile.scss';

import { Props } from './DatePicker.types';

const DatePicker = (props: Props) => {
  const isMobile = useMediaQuery('(max-device-width: 768px)');

  return (
    <ReactDatePicker
      inline
      weekLabel=" "
      fixedHeight
      locale={props.locale ?? fr}
      calendarClassName={
        isMobile
          ? 'randstad_react-datepicker__mobile-date-picker-container'
          : 'randstad_react-datepicker__date-picker-container'
      }
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      openToDate={props.selected ?? new Date()}
      useWeekdaysShort
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        decreaseYear,
        increaseYear,
      }) => (
        <div className="react-datepicker__custom-header">
          <div>
            <p>{moment(date).format('MMMM YYYY')}</p>
            {props.showYearDropdown && (
              <div className="react-datepicker__change-year-container">
                <DownArrow className="react-datepicker__up-arrow" onClick={increaseYear} />
                <DownArrow className="react-datepicker__down-arrow" onClick={decreaseYear} />
              </div>
            )}
          </div>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <FlecheLeft />
          </button>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <FlecheRight />
          </button>
        </div>
      )}
      {...props}
    />
  );
};

export default DatePicker;
