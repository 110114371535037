import classNames from 'classnames';
import React from 'react';

import styles from './Base.module.scss';
import { BaseProps } from './Base.types';

const Base = ({ icon, children, className }: BaseProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Base;
