import { LightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import RawDropdown from '../../RawDropdown';

import styles from './DropDown.module.scss';
import { Props } from './DropDown.types';

const DropDown = <T,>({ placeholder, className, triggerIcon, ...props }: Props<T>) => {
  const { selectedItem, disabled, keyValueExtractor } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [resultWidth, setResultWidth] = useState(containerRef.current?.offsetWidth ?? 0);

  useEffect(() => {
    function handleResize() {
      setResultWidth(containerRef.current?.offsetWidth ?? 0);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  useEffect(() => {
    setResultWidth(containerRef.current?.offsetWidth ?? 0);
  }, [containerRef]);

  const spin = useSpring({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  return (
    <RawDropdown
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      width={resultWidth}
      placeholder={placeholder}
      trigger={
        <div
          ref={containerRef}
          className={classnames(
            styles.container,
            {
              [styles.containerFocused]: open,
              [styles.containerWithSelectedValue]: selectedItem !== undefined,
              [styles.disabled]: disabled,
            },
            className
          )}
        >
          <div className={styles.triggerWithIcon}>
            {triggerIcon}
            <span className={styles.placeholder}>
              {selectedItem ? keyValueExtractor(selectedItem).value : placeholder}
            </span>
          </div>
          <animated.div
            className={classnames(styles.arrow, { [styles.disabledArrow]: disabled })}
            style={spin}
          >
            <LightArrow className={classnames({ [styles.disabledArrow]: disabled })} />
          </animated.div>
        </div>
      }
      {...props}
    />
  );
};

export default DropDown;
