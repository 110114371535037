import classNames from 'classnames';
import React from 'react';

import Checkbox from '../../Forms/Checkbox';
import ComboBox from '../../Forms/SearchInput/ComboBox';
import WithLightTitle from '../../WithLightTitle';

import styles from './ComboBoxWithCheckbox.module.scss';
import { Props } from './ComboBoxWithCheckbox.types';

function ComboBoxWithCheckBox<T>({
  comboId,
  comboSearchResults,
  comboKeyValueExtractor,
  comboBoxOnChange,
  comboBoxFetchStatus,
  comboBoxValue,
  comboBoxPlaceholder,
  comboBoxTitle,
  checkboxesData,
  checkboxesTitle,
  checkboxContainerClassName,
  containerClassName,
  isOptionEqualToValue,
}: Props<T>) {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <WithLightTitle title={comboBoxTitle} className={styles.comboBox}>
        <ComboBox
          className={styles.comboBox}
          id={comboId}
          searchResults={comboSearchResults}
          useNativeOptionFiltering
          keyValueExtractor={comboKeyValueExtractor}
          onChange={comboBoxOnChange}
          fetchStatus={comboBoxFetchStatus}
          value={comboBoxValue}
          placeholder={comboBoxPlaceholder}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      </WithLightTitle>
      <div>
        <WithLightTitle title={checkboxesTitle ?? ''} className={styles.checkboxLegend}>
          <div className={styles.checkboxesContainer}>
            {checkboxesData.map((checkboxData, index) => {
              return (
                <div className={checkboxContainerClassName}>
                  <Checkbox
                    key={index}
                    label={checkboxData.label}
                    checked={checkboxData.checked}
                    onChange={checkboxData.onChange}
                  />
                </div>
              );
            })}
          </div>
        </WithLightTitle>
      </div>
    </div>
  );
}

export default ComboBoxWithCheckBox;
