import { InclusiveSelectionGroup as InclusiveSelectionGroupComponent } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import { Props } from './InclusiveSelectionGroup.types';

function InclusiveSelectionGroup<
  TFieldValues extends FieldValues,
  TValue,
  TFormValue,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  defaultValue,
  getSelectedValues,
  getSelectedFormValue,
  ...inclusiveSelectionGroupProps
}: Props<TFieldValues, TValue, TFormValue, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <InclusiveSelectionGroupComponent
        {...inclusiveSelectionGroupProps}
        selected={getSelectedValues(field.value as TFormValue)}
        setSelected={values => {
          field.onChange(getSelectedFormValue(values));
        }}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default InclusiveSelectionGroup;
