import {
  WhiteCheckmarkCircle,
  WrongCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import styles from './ToggleSwitch.module.scss';
import { Props } from './ToggleSwitch.types';

function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

const ToggleSwitch = (props: Props) => {
  const [id] = useState(guidGenerator());

  return (
    <>
      <input
        className={styles.reactSwitchCheckbox}
        id={id}
        type="checkbox"
        checked={props.checked}
        onChange={event => {
          props.onCheckStatusChange(event.target.checked);
        }}
      />
      <label
        className={classnames(styles.reactSwitchLabel, {
          [styles.reactSwitchLabelChecked]: props.checked,
        })}
        htmlFor={id}
      >
        <span className={styles.reactSwitchButton}>
          {props.checked ? <WhiteCheckmarkCircle /> : <WrongCircle />}
        </span>
      </label>
    </>
  );
};

export default ToggleSwitch;
