import classnames from 'classnames';
import React from 'react';
import { useState } from 'react';

import PopupMenu from '../../Popups/PopupMenu/PopupMenu.component';

import styles from './ActionsMiniPopup.module.scss';
import { Props } from './ActionsMiniPopup.types';

const ActionsMiniPopup = ({ icon, label, className, ...props }: Props) => {
  const [opened, setOpened] = useState(false);
  const trigger = (
    <button className={classnames(styles.container, className, { [styles.selected]: opened })}>
      <div className={styles.icon}>{icon}</div>
      <div>{label}</div>
    </button>
  );
  return (
    <PopupMenu
      trigger={trigger}
      arrow={false}
      position="bottom left"
      onOpen={() => {
        setOpened(true);
        props.onOpen?.();
      }}
      onClose={() => {
        setOpened(false);
        props.onClose?.();
      }}
      {...props}
    />
  );
};

export default ActionsMiniPopup;
