import { RANDSTAD_APP } from './AppsDetailsValues/AppsDetailsValues';

export enum BRAND {
  RANDSTAD = 'VB',
  INHOUSE = 'RIS',
  APPEL_MEDICAL = 'AM',
  EXPECTRA = 'EE',
  SEARCH = 'SEARCH',
}

const brandEcosystems: Record<BRAND, RANDSTAD_APP[]> = {
  [BRAND.RANDSTAD]: [
    RANDSTAD_APP.POM,
    RANDSTAD_APP.EDP,
    RANDSTAD_APP.FLASH,
    RANDSTAD_APP.RECRUTLIVE,
    RANDSTAD_APP.TDP,
    RANDSTAD_APP.SATISFACTION,
    RANDSTAD_APP.PUZZLE,
    RANDSTAD_APP.PIGE,
    RANDSTAD_APP.SCOPE,
    RANDSTAD_APP.IT,
  ],
  [BRAND.INHOUSE]: [
    RANDSTAD_APP.POM,
    RANDSTAD_APP.EDP,
    RANDSTAD_APP.FLASH,
    RANDSTAD_APP.RECRUTLIVE,
    RANDSTAD_APP.TDP,
    RANDSTAD_APP.SATISFACTION,
    RANDSTAD_APP.PIGE,
    RANDSTAD_APP.SCOPE,
    RANDSTAD_APP.IT,
  ],
  [BRAND.APPEL_MEDICAL]: [
    RANDSTAD_APP.POM,
    RANDSTAD_APP.SATISFACTION,
    RANDSTAD_APP.PIGE,
    RANDSTAD_APP.SCOPE,
    RANDSTAD_APP.IT,
  ],
  [BRAND.EXPECTRA]: [
    RANDSTAD_APP.POM,
    RANDSTAD_APP.SATISFACTION,
    RANDSTAD_APP.PIGE,
    RANDSTAD_APP.SCOPE,
    RANDSTAD_APP.IT,
  ],
  [BRAND.SEARCH]: [
    RANDSTAD_APP.POM,
    RANDSTAD_APP.BDP,
    RANDSTAD_APP.PIGE,
    RANDSTAD_APP.SCOPE,
    RANDSTAD_APP.IT,
  ],
};

const mobileBrandEcosystems: Record<BRAND, RANDSTAD_APP[]> = {
  [BRAND.RANDSTAD]: [RANDSTAD_APP.EDP, RANDSTAD_APP.TDP, RANDSTAD_APP.SCOPE],
  [BRAND.INHOUSE]: [RANDSTAD_APP.EDP, RANDSTAD_APP.TDP, RANDSTAD_APP.SCOPE],
  [BRAND.APPEL_MEDICAL]: [RANDSTAD_APP.SCOPE],
  [BRAND.EXPECTRA]: [RANDSTAD_APP.SCOPE],
  [BRAND.SEARCH]: [RANDSTAD_APP.SCOPE],
};

export const getAppEcosystem = ({
  brand,
  isMobile,
}: {
  brand: string;
  isMobile?: boolean;
}): RANDSTAD_APP[] => {
  if (['VB', 'RDI'].includes(brand)) {
    return isMobile ? mobileBrandEcosystems[BRAND.RANDSTAD] : brandEcosystems[BRAND.RANDSTAD];
  }
  if (['RIS', 'ROS'].includes(brand)) {
    return isMobile ? mobileBrandEcosystems[BRAND.INHOUSE] : brandEcosystems[BRAND.INHOUSE];
  }
  if (['AM', 'JBM'].includes(brand)) {
    return isMobile
      ? mobileBrandEcosystems[BRAND.APPEL_MEDICAL]
      : brandEcosystems[BRAND.APPEL_MEDICAL];
  }
  if (['EE', 'EXE'].includes(brand)) {
    return isMobile ? mobileBrandEcosystems[BRAND.EXPECTRA] : brandEcosystems[BRAND.EXPECTRA];
  }
  if (['RSS', 'AMS', 'EES'].includes(brand)) {
    return isMobile ? mobileBrandEcosystems[BRAND.SEARCH] : brandEcosystems[BRAND.SEARCH];
  }
  return isMobile ? mobileBrandEcosystems[BRAND.RANDSTAD] : brandEcosystems[BRAND.RANDSTAD];
};
