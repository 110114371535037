import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import styles from './ErrorMessage.module.scss';
import { Props } from './ErrorMessage.types';

function usePersistedValue<T>(value?: T) {
  const persistedValue = useRef(value);
  useEffect(() => {
    if (value) {
      persistedValue.current = value;
    }
  }, [value]);

  return value ?? persistedValue.current;
}

const ErrorMessage = ({ error }: Props) => {
  const persistedError = usePersistedValue(error?.message);

  return (
    <div className={classnames(styles.container, { [styles.expand]: error !== undefined })}>
      <p className={styles.error}>{persistedError}</p>
    </div>
  );
};

export default ErrorMessage;
