import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Props } from './DragDropListItem.types';

const DragDropListItem = ({ item, index, draggableItemClassname }: Props) => {
  return item.draggable === false ? (
    <div key={item.id} className={draggableItemClassname}>
      {item.item}
    </div>
  ) : (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {provided => (
        <div
          className={draggableItemClassname}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {item.item}
        </div>
      )}
    </Draggable>
  );
};

export default DragDropListItem;
