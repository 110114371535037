import { FlecheLeft } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { AppData, apps } from './../AppsDetailsValues/AppsDetailsValues';
import { getAppEcosystem } from './../getAppEcosystem';
import styles from './RandstadMobileAppsPopup.module.scss';
import { Props } from './RandstadMobileAppsPopup.types';

const RandstadMobileAppsPopup = ({ onItemClick, isProd, brandCode, onArrowClick }: Props) => {
  let activeApps: AppData[] = apps;
  if (brandCode) {
    activeApps = apps.filter(app =>
      getAppEcosystem({ brand: brandCode, isMobile: true }).includes(app.name)
    );
  }
  return (
    <div>
      <div className={styles.appMenuHeader}>
        <FlecheLeft className={styles.arrowButton} onClick={onArrowClick} />
        <p className={styles.whiteTitle}>inter apps</p>
      </div>
      <div className={styles.lineSeparator} />
      {activeApps.map(({ icon, name, link }) => (
        <div
          className={styles.appButtonContainer}
          onClick={() => {
            onItemClick?.(name, isProd ? link.prod : link.uat);
            window.open(isProd ? link.prod : link.uat, '_blank', 'noopener,noreferrer');
          }}
        >
          <div className={styles.iconContainer}>{isProd ? icon.mobileProd : icon.mobileUat}</div>
          <div className={styles.appName}>{name}</div>
        </div>
      ))}
    </div>
  );
};

export default RandstadMobileAppsPopup;
