import { css } from '@emotion/css';
import classNames from 'classnames';
import React from 'react';

import { AppData, apps, RANDSTAD_APP } from '../AppsDetailsValues/AppsDetailsValues';
import { getAppEcosystem } from '../getAppEcosystem';
import { PopupActions } from '../Modals';

import AppButton from './AppButton';
import HighlightedAppButton from './HighlightedAppButton';
import styles from './RandstadAppsDetails.module.scss';
import { Props } from './RandstadAppsDetails.types';

const RandstadAppsDetails = React.forwardRef<PopupActions, Props>(
  ({ onItemClick, isProd, brandCode, appsDescriptions, highlightedApp, width }) => {
    let activeApps: AppData[] = apps.filter(app => app.name !== RANDSTAD_APP.POM);
    if (brandCode) {
      activeApps = apps.filter(app => getAppEcosystem({ brand: brandCode }).includes(app.name));
    }
    let hightlightedAppData: AppData | undefined = undefined;
    if (highlightedApp) {
      hightlightedAppData = activeApps.find(app => app.name === highlightedApp);
      activeApps = activeApps.filter(app => app.name !== highlightedApp);
    }
    return (
      <div
        className={classNames(
          styles.container,
          css`
            width: ${width};
          `
        )}
      >
        {hightlightedAppData && (
          <HighlightedAppButton
            key={hightlightedAppData.name}
            name={hightlightedAppData.fullName}
            backgroundStyle={hightlightedAppData.iconStyle}
            category={hightlightedAppData.category}
            description={appsDescriptions ? appsDescriptions[hightlightedAppData.name] : ''}
            link={isProd ? hightlightedAppData.link.prod : hightlightedAppData.link.uat}
            onClick={onItemClick}
          />
        )}
        <div className={styles.appsContainer}>
          {activeApps.map(appDetailsValues => (
            <AppButton
              key={appDetailsValues.name}
              icon={isProd ? appDetailsValues.icon.prod : appDetailsValues.icon.uat}
              iconStyle={appDetailsValues.iconStyle}
              name={appDetailsValues.fullName}
              category={appDetailsValues.category}
              description={appsDescriptions ? appsDescriptions[appDetailsValues.name] : ''}
              link={isProd ? appDetailsValues.link.prod : appDetailsValues.link.uat}
              onClick={onItemClick}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default RandstadAppsDetails;
