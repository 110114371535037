import classnames from 'classnames';
import React from 'react';

import Badge from '../../Badge';

import styles from './KanbanCard.module.scss';
import { Props } from './KanbanCard.types';

const KanbanCard = ({ title, className, subtitle, badges, actionButton }: Props) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.popupMenu}>{actionButton}</div>
      <div className={styles.badgesContainer}>
        {badges?.map(badge => (
          <Badge
            value={badge.badgeTitle}
            className={classnames(styles.badge, badge.badgeClassName)}
          />
        ))}
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};

export default KanbanCard;
