import classNames from 'classnames';
import fr from 'date-fns/locale/fr';
import React from 'react';
import DatePicker from 'react-datepicker';

import './DateRangePicker.scss';
import { Props } from './DateRangePicker.types';

const convert = (dates: Date | [Date | null, Date | null] | null) =>
  Array.isArray(dates) ? dates : dates ? [dates] : [];

const DateRangePicker = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
  withYearSelection,
  monthsShown = 2,
}: Props) => {
  return (
    <DatePicker
      inline
      locale={fr}
      selectsRange
      monthsShown={monthsShown}
      calendarClassName={classNames(['randstad_react-datepicker__date-range-picker-container'])}
      selected={startDate}
      onChange={dates => {
        const [start, end] = convert(dates);
        onChange([start ?? undefined, end ?? undefined]);
      }}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      showYearDropdown={withYearSelection}
      showPopperArrow={false}
    />
  );
};

export default DateRangePicker;
