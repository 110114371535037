import classnames from 'classnames';
import React, { useRef, useEffect } from 'react';

import styles from './SegmentedMenuItem.module.scss';
import { Props } from './SegmentedMenuItem.types';

const SegmentedMenuItem = ({
  isSelected,
  label,
  leftIcon,
  leftIconClassName,
  rightIcon,
  rightIconClassName,
  onSelect,
  setBoundingRect,
  selectedTextClassName,
  controlItemClassName,
}: Props) => {
  const ref = useRef<HTMLSpanElement>(null);

  const boundingClientRect = ref.current?.getBoundingClientRect();
  useEffect(() => {
    const handleResize = () => {
      const currentBoundingClientRect = ref.current?.getBoundingClientRect();
      if (currentBoundingClientRect && isSelected) {
        setBoundingRect(currentBoundingClientRect);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, boundingClientRect?.width, boundingClientRect?.left, boundingClientRect?.bottom]);
  return (
    <label className={classnames(styles.tabItemContainer, controlItemClassName)}>
      <span
        className={classnames(
          { [styles.selectedText]: isSelected, [selectedTextClassName ?? '']: isSelected },
          styles.label
        )}
        ref={ref}
      >
        {leftIcon && (
          <div className={classnames(styles.iconContainer, leftIconClassName)}>{leftIcon}</div>
        )}
        {label}
        {rightIcon && (
          <div className={classnames(styles.iconContainer, rightIconClassName)}>{rightIcon}</div>
        )}
      </span>

      <input
        className={styles.input}
        type="radio"
        name="radioBtn"
        onClick={() => {
          !isSelected && onSelect();
        }}
      />
    </label>
  );
};

export default SegmentedMenuItem;
