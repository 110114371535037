import { ComboBox as ComboBoxComponent } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import { Props } from './ComboBox.types';
function ComboBox<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  name,
  control,
  defaultValue,
  containerClassName,
  ...comboBoxProps
}: Props<TFieldValues, TName, T, Multiple, DisableClearable>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <div className={containerClassName}>
      <ComboBoxComponent {...comboBoxProps} isError={error !== undefined} {...field} />
      <ErrorMessage error={error} />
    </div>
  );
}

export default ComboBox;
