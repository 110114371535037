import {
  MinusBar,
  AddCrossPartial,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import Animation from '../Animations';
import HorizontalTextIconCard from '../HorizontalTextIconCard';

import styles from './HorizontalCardFoldable.module.scss';
import { Props } from './HorizontalCardFoldable.types';

const HorizontalCardFoldable = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

  const mergedIsOpen = props.isOpen !== undefined ? props.isOpen : isOpen;

  return (
    <>
      <HorizontalTextIconCard
        leftIcon={props.leftIcon}
        leftIconClassName={props.leftIconClassName}
        containerClassName={props.containerClassName}
        rightComponent={props.rightComponent}
        label={props.label}
        overLabel={props.overLabel}
        disabled={props.disabled}
        onRightIconClick={() => {
          setIsOpen(!isOpen);
          props.onRightIconClick?.();
        }}
        rightIcon={
          !props.disabled && mergedIsOpen ? (
            <MinusBar className={styles.icon} />
          ) : (
            <AddCrossPartial className={styles.icon} />
          )
        }
      />
      <Animation.Unroll visible={mergedIsOpen}>{props.children}</Animation.Unroll>
    </>
  );
};

export default HorizontalCardFoldable;
