import { UpArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './TopButton.module.scss';
import { Props } from './TopButton.types';

const TopButton = ({ onClick }: Props) => {
  return (
    <div className={styles.topButton} onClick={onClick}>
      <UpArrow />
    </div>
  );
};

export default TopButton;
