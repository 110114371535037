import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Props } from './DragDropList.types';
import DragDropListItem from './DragDropListItem/DragDropListItem.component';

const DragDropList = ({
  droppableId,
  items,
  draggableItemClassname,
  droppableZoneClassname,
  onDragEnd,
}: Props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {provided => (
          <ul
            className={droppableZoneClassname}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((listItem, idx) => {
              return (
                <DragDropListItem
                  item={listItem}
                  index={idx}
                  draggableItemClassname={draggableItemClassname}
                />
              );
            })}
            {provided.placeholder as React.ReactNode}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropList;
