import classnames from 'classnames';
import React from 'react';

import styles from './SelectionIconButton.module.scss';
import { Props } from './SelectionIconButton.types';

const SelectionIconButton = ({
  icon,
  label,
  selected,
  onClick,
  className,
  labelClassName,
}: Props) => (
  <div
    className={classnames(styles.container, { [styles.selected]: selected }, className)}
    onClick={onClick}
  >
    <div className={styles.icon}>{icon}</div>
    <div className={classnames(styles.label, labelClassName)}>{label}</div>
  </div>
);

export default SelectionIconButton;
