import {
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteValue as MuiAutocompleteValue,
} from '@mui/material';
import { ReactNode } from 'react';

export const FETCH_STATUS = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_STATUS = (typeof FETCH_STATUS)[keyof typeof FETCH_STATUS];

export type AutocompleteValue<T, Multiple, DisableClearable> = MuiAutocompleteValue<
  T,
  Multiple,
  DisableClearable,
  false
>;

export type KeyValue = { key: string; value: string; subValue?: string };

export type VirtualOptionProps = {
  liProps: React.HTMLAttributes<HTMLLIElement> & { key: string };
  parts: {
    text: string;
    highlight: boolean;
  }[];
  subLabel: string | undefined;
};

export type Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
> = {
  id: string;
  className?: string;
  inputClassName?: string;
  searchResults: T[];
  filterResults?: (keyword: string, results: T[]) => T[];
  keyValueExtractor: (searchResult: T) => KeyValue;
  onChange: (searchResult: AutocompleteValue<T, Multiple, DisableClearable>) => void;
  value?: AutocompleteValue<T, Multiple, DisableClearable>;
  multiple?: Multiple;
  resetLabel?: string;
  minLengthToSearch: number;
  fetchStatus: FETCH_STATUS;
  disabled?: boolean;
  placeholder?: string;
  displayValue?: string;
  disableClearable: DisableClearable;
  showSelected?: boolean;
  useNativeOptionFiltering?: boolean;
  onSearch?: (searchText: string) => void;
  renderTags?: (value: T[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  limitTags?: number;
  withSubLabel?: boolean;
  noOptionsText?: string;
  popupIcon?: ReactNode;
  isError?: boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
} & Pick<AutocompleteProps<T, Multiple, DisableClearable, false>, 'groupBy'>;

export const defaultProps = {
  disableClearable: false,
  multiple: false,
  fetchStatus: FETCH_STATUS.FULFILLED,
  minLengthToSearch: 0,
};
