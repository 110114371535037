import { DurationPicker as DurationPickerComponent } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import styles from './DurationPicker.module.scss';
import { Props, Duration } from './DurationPicker.types';

function DurationPicker<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  defaultValue,
  containersClassName,
  ...durationPickerProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const { hours, minutes } = field.value as Duration;
  const duration = moment.duration(hours * 60 + minutes, 'm');

  return (
    <div>
      <DurationPickerComponent
        onChange={value => {
          field.onChange({ hours: Math.floor(value.asHours()), minutes: value.minutes() });
        }}
        duration={duration}
        {...durationPickerProps}
        containersClassName={classnames(containersClassName, {
          [styles.error]: error !== undefined,
        })}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default DurationPicker;
