import React from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import Button from '../../Buttons';
import LetterBadge from '../../LetterBadge';
import Popup from '../../Popups/Popup';

import styles from './ParametersPopup.module.scss';
import { Props } from './ParametersPopup.types';

const ParametersPopup = React.forwardRef<PopupActions, Props>(
  ({ firstName, lastName, siid, email, onLogout, children, version, ...popupProps }, ref) => {
    const pellet = `${firstName?.[0]}${lastName?.[0]}`;
    return (
      <Popup {...popupProps} ref={ref}>
        <div className={styles.popOverContent}>
          {pellet && <LetterBadge text={pellet} className={styles.letterBadgeContainer} />}
          <div>
            {(firstName || lastName) && (
              <p className={styles.text}>
                {firstName} {lastName}
              </p>
            )}
            {siid && <p className={styles.subText}>{siid}</p>}
            {email && <p className={styles.subText}>{email}</p>}
          </div>
          {children && <div className={styles.separator} />}
          <div>
            {React.Children.map(children, (child, i) => (
              <div key={`parameters-popup-child-${i}`} className={styles.childContainer}>
                {child}
              </div>
            ))}
          </div>
          <div className={styles.buttonMargin}>
            <Button.Secondary
              onClick={onLogout}
              text={'déconnexion'}
              className={styles.logoutButton}
            />
          </div>
          {version && <div className={styles.version}>{`version :  ${version}`}</div>}
        </div>
      </Popup>
    );
  }
);

export default ParametersPopup;
