import React from 'react';

import RawSearchInput from '../RawSearchInput';

import { defaultProps, Props } from './AutoSuggest.types';

function AutoSuggest<T>(props: Props<T>) {
  return <RawSearchInput disableClearable multiple {...props} />;
}

AutoSuggest.defaultProps = defaultProps;

export default AutoSuggest;
