import { ExclusiveSelectionGroup as ExclusiveSelectionGroupComponent } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import { Props } from './ExclusiveSelectionGroup.types';

function ExclusiveSelectionGroup<
  TFieldValues extends FieldValues,
  TValue,
  TFormValue,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  defaultValue,
  getSelectedValue,
  getSelectedFormValue,
  ...exclusiveSelectionGroupProps
}: Props<TFieldValues, TValue, TFormValue, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <ExclusiveSelectionGroupComponent
        {...exclusiveSelectionGroupProps}
        selected={getSelectedValue(field.value as TFormValue)}
        setSelected={value => {
          field.onChange(getSelectedFormValue(value));
        }}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default ExclusiveSelectionGroup;
