import React from 'react';
import { Accept } from 'react-dropzone';

export interface Props {
  items: DropzoneItem[];
  accept?: Accept;
  maxSize?: number;
  maxFiles?: number;
  onUpload: (items: DropzoneItem[]) => void;
  onDelete: (item: DropzoneItem) => void;
  onClick: (item: DropzoneItem) => void;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
}

export enum UploadStatus {
  None,
  InProgress,
  Failed,
  Done,
}

export interface DropzoneItem {
  id?: string;
  file: File;
  subtitle?: string;
  status: UploadStatus;
}
