import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useScreenshot } from 'use-react-screenshot';

import FileDropzone from '../Forms/FileDropzone/FileDropzone.component';
import SegmentedControl from '../Forms/SegmentedControl';
import SignatureDrawer from '../SignatureDrawer/SignatureDrawer.component';

import styles from './SignatureWithChoice.module.scss';
import {
  Props,
  SIGNATURE_MODE,
  SIGNATURE_MODE_DESKTOP,
  SIGNATURE_MODE_MOBILE,
} from './SignatureWithChoice.types';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const SignatureWithChoice = ({
  onClear,
  onSignChange,
  size,
  containerClassName,
  disabled,
  isMobile,
}: Props) => {
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [signatureMode, setSignatureMode] = useState<SIGNATURE_MODE>(SIGNATURE_MODE.SIGN);
  const [downloadSign, setDownloadSign] = useState<string>();
  const [textSign, setTextSign] = useState('');
  const [image, takeScreenshot] = useScreenshot();

  const signatureModeDevice = useMemo(
    () => (isMobile ? SIGNATURE_MODE_MOBILE : SIGNATURE_MODE_DESKTOP),
    [isMobile]
  );

  useEffect(() => {
    if (signatureMode === SIGNATURE_MODE.TYPE && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [signatureMode]);

  useEffect(() => {
    if (ref.current !== null && textSign !== '') {
      takeScreenshot(ref.current);
    } else if (ref.current !== null && textSign === '') {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSign]);
  useEffect(() => {
    onSignChange(image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const signatureComponent = () => {
    switch (signatureMode) {
      case SIGNATURE_MODE.SIGN:
        return (
          <SignatureDrawer
            onClear={() => onClear()}
            onEnd={image => onSignChange(image)}
            size={size}
            disabled={disabled}
          />
        );
      case SIGNATURE_MODE.TYPE:
        return (
          <div className={styles.typeContainer}>
            <div className={styles.downloadImage} style={size}>
              <input
                disabled={disabled}
                value={textSign}
                ref={inputRef}
                onChange={e => {
                  setTextSign(e.currentTarget.value);
                }}
                className={styles.input}
              />
            </div>
            <div className={styles.padHeader}>saisissez votre signature</div>
            <div
              className={styles.clearButton}
              onClick={() => {
                setTextSign('');
                onClear();
              }}
            >
              effacer
            </div>
            <div className={styles.copy}>
              <div className={styles.downloadImage} style={size} ref={ref}>
                <p className={styles.input}>{textSign}</p>
              </div>
            </div>
          </div>
        );
      case SIGNATURE_MODE.DOWNLOAD:
        return (
          <>
            {downloadSign ? (
              <>
                <div className={styles.downloadImage} style={size}>
                  <img src={downloadSign} alt={'sign'} className={styles.image} />
                </div>
                <div
                  className={styles.clearButton}
                  style={{ left: `${(size?.width ?? 672) - 72}px` }}
                  onClick={() => {
                    onClear();
                    setDownloadSign(undefined);
                  }}
                >
                  effacer
                </div>
              </>
            ) : (
              <FileDropzone
                {...{
                  maxFiles: 1,
                  maxSize: 5000000,
                  accept: {
                    'image/*': ['.jpg', '.png', '.gif'],
                  },
                  advice: 'ajouter une photo de la signature',
                }}
                items={[]}
                onUpload={async items => {
                  if (items.length > 0) {
                    const fileAsString = await toBase64(items[0].file);
                    onSignChange(fileAsString as string);
                    setDownloadSign(fileAsString as string);
                  }
                }}
                onClick={() => 0}
                onDelete={() => 0}
                containerStyle={{ height: size?.height, justifyContent: 'center' }}
                disabled={disabled}
              />
            )}
          </>
        );
    }
  };
  return (
    <div
      className={classnames(styles.container, containerClassName)}
      style={{ width: size?.width }}
    >
      <SegmentedControl
        className={styles.segmentedControl}
        controls={Object.values(signatureModeDevice)}
        getValue={item => item}
        selected={signatureModeDevice[signatureMode]}
        onSelectionChange={item => {
          onClear();
          setSignatureMode(
            (Object.keys(signatureModeDevice).find(
              key => signatureModeDevice[key] === item
            ) as SIGNATURE_MODE) ?? SIGNATURE_MODE.SIGN
          );
          if (item === SIGNATURE_MODE.DOWNLOAD) {
            setDownloadSign(undefined);
          } else if (item === SIGNATURE_MODE.TYPE) {
            setTextSign('');
          }
        }}
      />
      {signatureComponent()}
    </div>
  );
};

export default SignatureWithChoice;
