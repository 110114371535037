import classnames from 'classnames';
import React, { useState } from 'react';

import styles from './SegmentedMenu.module.scss';
import { Props } from './SegmentedMenu.types';
import SegmentedMenuItem from './SegmentedMenuItem';
import { BoundingRect } from './SegmentedMenuItem/SegmentedMenuItem.types';

function SegmentedMenu<T>({
  controls,
  selected,
  onSelectionChange,
  keyValueExtractor,
  className,
  leftIconClassName,
  rightIconClassName,
  controlItemClassName,
  selectedTextClassName,
  bottomBarClassName,
}: Props<T>) {
  const [boundingRect, setBoundingRect] = useState<BoundingRect>();

  return (
    <>
      <div className={classnames(styles.tabMenuContainer, className)}>
        {controls.map(control => {
          const { key, value, leftIcon, rightIcon } = keyValueExtractor(control);
          return (
            <SegmentedMenuItem
              label={value}
              leftIcon={leftIcon}
              leftIconClassName={leftIconClassName}
              rightIcon={rightIcon}
              rightIconClassName={rightIconClassName}
              isSelected={selected === key}
              key={key}
              onSelect={() => onSelectionChange(control)}
              setBoundingRect={setBoundingRect}
              controlItemClassName={controlItemClassName}
              selectedTextClassName={selectedTextClassName}
            />
          );
        })}
      </div>
      <div
        className={classnames(styles.bottomBar, bottomBarClassName)}
        style={{
          display: boundingRect === undefined ? 'none' : 'inline-block',
          width: boundingRect?.width ?? 0,
          left: boundingRect?.left ?? 0,
          top: boundingRect?.bottom ? boundingRect?.bottom + 9 : 0,
        }}
      />
      <div className={styles.horizontalSeparator} />
    </>
  );
}

export default SegmentedMenu;
