import { Check, FlecheRight } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useContext } from 'react';

import { PopupMenuContext } from '../Context';

import styles from './PopupMenuItem.module.scss';
import { Props } from './PopupMenuItem.types';

const PopupMenuItem = ({
  id,
  icon,
  rightIcon,
  text,
  onClick,
  keepOpenOnClick,
  hasSubMenu,
  className,
  disabled,
  openSubMenu,
}: Props) => {
  const context = useContext(PopupMenuContext);
  const isSelected = id !== undefined && context?.().selectedIds?.includes(id);
  if (!context) {
    throw new Error(`PopupMenu.Item cannot be rendered outside a PopupMenu component`);
  }
  return (
    <div
      className={classnames(styles.container, className)}
      onClick={() => {
        if (!disabled) {
          if (!keepOpenOnClick) {
            context?.().actions?.close();
          }
          onClick?.();
        }
      }}
    >
      <div
        className={classnames(styles.contentContainer, {
          [styles.selected]: isSelected || openSubMenu,
          [styles.disabled]: disabled,
        })}
      >
        <div className={styles.iconAndText}>
          {icon && <div className={styles.leftIcon}>{icon}</div>}
          {icon ? <div className={styles.separator} /> : null}
          <div className={styles.text}>{text}</div>
        </div>
        <div>
          <div className={styles.rightIcon}>
            {isSelected && !hasSubMenu && <Check className={styles.checkBox} />}
            {hasSubMenu && <FlecheRight className={styles.subMenuIcon} />}
          </div>
          {!hasSubMenu && !isSelected && rightIcon && (
            <div className={styles.rightIcon}>{rightIcon}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupMenuItem;
