import React, { useRef } from 'react';
import { animated, useSpring } from 'react-spring';

import { Props } from './HorizontalUnroll.types';

const HorizontalUnroll = ({
  children,
  visible,
  className,
  noOpacityFade,
  config,
  immediate,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const animatedStyle = useSpring({
    width: visible ? ref.current?.scrollWidth : 0,
    opacity: noOpacityFade === true ? 1 : visible ? 1 : 0,
    overflow: 'hidden',
    config: config,
    immediate: immediate,
  });

  return (
    <animated.div style={animatedStyle}>
      <div ref={ref} className={className}>
        {children}
      </div>
    </animated.div>
  );
};

export default HorizontalUnroll;
