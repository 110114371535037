import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './HorizontalCardWithTitleLoader.module.scss';

const HorizontalCardWithTitleLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="5.75rem" width="100%" uniqueKey="positionStudyCard">
      <rect x="0%" y="5" rx="4" ry="4" width="30%" height="15" />
      <rect x="0%" y="35" rx="4" ry="4" width="20%" height="8" />
      <rect x="0%" y="50" rx="4" ry="4" width="50%" height="8" />
    </ContentLoader>
  </div>
);

export default HorizontalCardWithTitleLoader;
