import { OrganizationChartItem } from 'OrganizationChart/OrganizationChart.types';

export const UpdateElementInData = ({
  id,
  data,
  property,
  value,
}: {
  id: number;
  data: OrganizationChartItem[];
  property: string;
  value: string | OrganizationChartItem[];
}) => {
  return data.map(item => {
    if (id === item.id) {
      item[property] = value;
      return item;
    } else if (item.children && item.children.length > 0) {
      const itemChildren: OrganizationChartItem = {
        ...item,
        children: UpdateElementInData({ id, data: item.children, property, value }),
      };
      return itemChildren;
    } else return item;
  });
};

export const deleteElementInTree = ({
  id,
  data,
}: {
  id: number;
  data: OrganizationChartItem[];
}): OrganizationChartItem[] => {
  return data.map(item => {
    const childrenIds = item.children?.map(dataItem => dataItem.id);
    if (childrenIds?.includes(id)) {
      return {
        ...item,
        children: item.children?.filter(item => item.id !== id),
      };
    } else if (item.children && item.children.length > 0) {
      const itemChildren: OrganizationChartItem = {
        ...item,
        children: deleteElementInTree({ id, data: item.children }),
      };
      return itemChildren;
    } else {
      return item;
    }
  });
};

export const findMaxIdInTree = (data: OrganizationChartItem[]): number => {
  const max = Math.max(
    ...data.map(item => item.id),
    ...data.map(item => item.children ?? [])?.map(findMaxIdInTree)
  );
  return max;
};
