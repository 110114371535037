import { useMediaQuery } from '@mui/material';
import { pluralFormat } from '@randstad-lean-mobile-factory/react-components-core/src/pluralFormat';
import { PaperClip } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './FileDropzone.module.scss';
import dropzoneStyles from './FileDropzone.style';
import { Props, UploadStatus } from './FileDropzone.types';
import FileDropzoneItem from './FileDropzoneItem';
import { bytesToSize } from './utils';

const FileDropzone = ({
  items,
  accept,
  maxSize,
  maxFiles,
  onClick,
  onUpload,
  onDelete,
  containerStyle,
  disabled,
}: Props) => {
  const isMobile = useMediaQuery('(max-device-width: 768px)');
  const failed = !!items.find(item => item.status === UploadStatus.Failed);
  const loading = !!items.find(item => item.status === UploadStatus.InProgress);
  const [hover, setHover] = useState(false);
  const [dropSuccess, setDropSuccess] = useState(true);
  const [lastFileSize, setLastFileSize] = useState(0);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      maxSize,
      maxFiles,
      noClick: (!!maxFiles && items.length >= maxFiles) || failed || loading || disabled,
      noKeyboard: (!!maxFiles && items.length >= maxFiles) || failed || loading || disabled,
      onDropRejected: items => {
        setDropSuccess(false);
        setLastFileSize(
          items.find(
            item => item.errors.find(error => error.code === 'file-too-large') !== undefined
          )?.file.size ?? 0
        );
      },
      onDropAccepted: () => {
        setDropSuccess(true);
        setLastFileSize(0);
      },
    });
  const overloaded = !!maxFiles && items.length >= maxFiles;
  const style = useMemo(
    () => ({
      ...dropzoneStyles.baseStyle,
      ...(hover && !overloaded && !disabled ? dropzoneStyles.focusedStyle : {}),
      ...(isDragActive ? dropzoneStyles.activeStyle : {}),
      ...(isDragAccept ? dropzoneStyles.acceptStyle : {}),
      ...(isDragReject || !dropSuccess ? dropzoneStyles.rejectStyle : {}),
      ...(overloaded || disabled ? dropzoneStyles.disabledStyle : {}),
      ...(isMobile ? dropzoneStyles.mobileStyle : {}),
      ...containerStyle,
    }),
    [
      hover,
      overloaded,
      isDragActive,
      isDragAccept,
      isDragReject,
      dropSuccess,
      disabled,
      isMobile,
      containerStyle,
    ]
  ) as React.CSSProperties;
  const fileItems = items.map(item => (
    <FileDropzoneItem
      key={item.file.name}
      item={item}
      deleteItem={() => onDelete(item)}
      onClick={() => onClick(item)}
    />
  ));
  useEffect(() => {
    if (!maxFiles || items.length + acceptedFiles.length <= maxFiles) {
      onUpload(
        items.concat(
          acceptedFiles
            .filter(file => !items.find(item => item.file.name === file.name))
            .map(file => ({
              file,
              subtitle: bytesToSize(file.size),
              status: UploadStatus.InProgress,
            }))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);
  return (
    <div className={styles.container}>
      <div
        {...getRootProps({ style, disabled: overloaded || disabled })}
        className={classnames({ [styles.mobileFileDropInput]: isMobile })}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <input {...getInputProps()} />
        {overloaded || loading ? (
          <p className={styles.titleDisabled}>
            {loading ? 'téléchargement en cours' : 'maximum atteint'}
          </p>
        ) : (
          <p className={styles.title}>
            <PaperClip className={styles.clipIcon} />
            ajouter un fichier
          </p>
        )}
        <p className={isMobile ? styles.mobileSubtitle : styles.subtitle}>
          {(overloaded || failed) && !loading
            ? 'supprimer un fichier pour réactiver le téléchargement'
            : `${!!accept && Object.values(accept).flat(1).join(', ')}${
                !!maxSize && ` - ${bytesToSize(maxSize)} maximum/fichier`
              }${!!maxFiles && ` - ${pluralFormat(maxFiles, 'fichier')} maximum`}`}
        </p>
      </div>
      {lastFileSize > (maxSize ?? 0) && (
        <div className={styles.errorMessage}>
          Le fichier que vous essayez de transmettre est trop lourd.
        </div>
      )}
      <div className={styles.fileList}>{fileItems}</div>
    </div>
  );
};

export default FileDropzone;
