import React, { useRef } from 'react';
import { animated, useSpring } from 'react-spring';

import { Props } from './Fade.types';

const Fade = ({ children, visible, className, config, immediate }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const animatedStyle = useSpring({
    opacity: visible ? 1 : 0,
    config: config,
    immediate: immediate,
  });

  return (
    <animated.div style={animatedStyle}>
      <div ref={ref} className={className}>
        {children}
      </div>
    </animated.div>
  );
};

export default Fade;
