import Error from './Error';
import Info from './Info';
import Success from './Success';
import Warning from './Warning';

const Message = Object.assign(Info, {
  Info,
  Error,
  Success,
  Warning,
});

export default Message;
