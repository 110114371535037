export interface Props {
  title: string;
  id: number;
  size?: OrganizationChartItemSize;
  subtitle?: string;
  className?: string;
  isRoot: boolean;
  onTextChange: ({ newText, isTitle }: { newText: string; isTitle?: boolean }) => void;
  onSizeChange: ({ newSize }: { newSize: OrganizationChartItemSize }) => void;
  addChild: () => void;
  addParent: () => void;
  deleteItem: () => void;
  isDisplayMode?: boolean;
}

export enum OrganizationChartItemSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
}
