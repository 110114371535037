import React from 'react';
import ReactJSPopup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import 'reactjs-popup/dist/index.css';

import { PopupProps } from './Popup.types';

const Popup = React.forwardRef<PopupActions, PopupProps>(
  ({ arrowStyle, contentStyle, overlayStyle, ...props }: PopupProps, ref) => (
    <div onClick={event => event.stopPropagation()}>
      <ReactJSPopup
        ref={ref}
        nested
        keepTooltipInside
        arrowStyle={{ strokeWidth: 0, ...arrowStyle }}
        contentStyle={{
          padding: 0,
          width: 'auto',
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
          ...contentStyle,
        }}
        overlayStyle={{ zIndex: 1, ...overlayStyle }}
        arrow={false}
        {...props}
      />
    </div>
  )
);

export default Popup;
