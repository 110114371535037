import React from 'react';

import { AppData, apps } from '../AppsDetailsValues/AppsDetailsValues';
import { getAppEcosystem } from '../getAppEcosystem';
import AppButton from '../Popups/RandstadAppsPopup/AppButton';

import styles from './RandstadMobileAppsGrid.module.scss';
import { Props } from './RandstadMobileAppsGrid.types';

const RandstadMobileAppsGrid = ({ brandCode, isProd, onItemClick }: Props) => {
  let activeApps: AppData[] = apps;
  if (brandCode) {
    activeApps = apps.filter(app =>
      getAppEcosystem({ brand: brandCode, isMobile: true }).includes(app.name)
    );
  }
  return (
    <div className={styles.container}>
      {activeApps.map(({ icon, name, link }) => (
        <AppButton
          key={name}
          containerClassName={styles.appButtonClassName}
          icon={isProd ? icon.prod : icon.uat}
          name={name}
          link={isProd ? link.prod : link.uat}
          onClick={onItemClick}
        />
      ))}
    </div>
  );
};

export default RandstadMobileAppsGrid;
