import { useMediaQuery } from '@mui/material';
import { Calendrier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import { DatePicker } from '../../Calendars';
import Modal from '../../Modals/Modal/Modal.component';
import Popup from '../../Popups/Popup/Popup.component';

import styles from './DatePickerInput.module.scss';
import { Props } from './DatePickerInput.types';

const DatePickerInput = ({
  date,
  className,
  onSelectDate,
  minDate,
  maxDate,
  position,
  ...rest
}: Props) => {
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery('(max-device-width: 768px)');
  const mobileModalRef = useRef<PopupActions>(null);
  const webModalRef = useRef<PopupActions>(null);

  return (
    <>
      {isMobile ? (
        <Modal
          title={<></>}
          hideCloseAction
          ref={mobileModalRef}
          trigger={
            <div
              className={classnames(styles.datePickerContainer, {
                [styles.datePickerContainerFocused]: opened,
                className,
              })}
            >
              <div
                className={classnames(
                  { [styles.dateValid]: date !== undefined },
                  { [styles.dateNotValid]: date === undefined },
                  styles.subContainer
                )}
              >
                {date ? moment(date).format('L') : 'JJ/MM/AAAA'}
              </div>
              <div className={styles.calendarIcon}>
                <Calendrier />
              </div>
            </div>
          }
        >
          <div className={styles.mobileCalendarContainer}>
            <DatePicker
              selected={date}
              onChange={date => {
                onSelectDate(date ?? undefined);
                mobileModalRef.current?.close();
              }}
              minDate={minDate}
              maxDate={maxDate}
              {...rest}
            />
          </div>
        </Modal>
      ) : (
        <Popup
          arrow={false}
          position={position}
          ref={webModalRef}
          trigger={
            <div
              className={classnames(styles.datePickerContainer, {
                [styles.datePickerContainerFocused]: opened,
                className,
              })}
            >
              <div
                className={classnames(
                  { [styles.dateValid]: date !== undefined },
                  { [styles.dateNotValid]: date === undefined },
                  styles.subContainer
                )}
              >
                {date ? moment(date).format('L') : 'JJ/MM/AAAA'}
              </div>
              <div className={styles.calendarIcon}>
                <Calendrier />
              </div>
            </div>
          }
          onOpen={() => setOpened(true)}
          onClose={() => setOpened(false)}
          nested={false}
        >
          <DatePicker
            selected={date}
            onChange={date => {
              onSelectDate(date ?? undefined);
              webModalRef.current?.close();
            }}
            minDate={minDate}
            maxDate={maxDate}
            {...rest}
          />
        </Popup>
      )}
    </>
  );
};

export default DatePickerInput;
