import { WhiteCheck, WhiteCross } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import Button from './../';
import Loader from './../../Loader';
import styles from './FetchButton.module.scss';
import { Props, BUTTON_STATUS } from './FetchButton.types';

const FetchButton = ({
  fetchStatus,
  title,
  errorTitle,
  onClick,
  className,
  secondary,
  onSuccess,
  onError,
  onBeforeErrorAnimation,
  onBeforeSuccessAnimation,
  stayFulfilled,
  ...buttonProps
}: Props) => {
  const [innerStatus, setInnerStatus] = useState<BUTTON_STATUS>(BUTTON_STATUS.PRISTINE);
  useEffect(() => {
    if (fetchStatus === BUTTON_STATUS.PENDING) {
      setInnerStatus(fetchStatus);
    }
    if (innerStatus === BUTTON_STATUS.PENDING && fetchStatus === BUTTON_STATUS.FULFILLED) {
      setInnerStatus(fetchStatus);
      onBeforeSuccessAnimation?.();
      setTimeout(() => {
        onSuccess?.();
        if (!stayFulfilled) {
          setInnerStatus(BUTTON_STATUS.PRISTINE);
        }
      }, 1000);
    }
    if (innerStatus !== BUTTON_STATUS.REJECTED && fetchStatus === BUTTON_STATUS.REJECTED) {
      setInnerStatus(fetchStatus);
      onBeforeErrorAnimation?.();
      setTimeout(() => {
        onError?.();
        setInnerStatus(BUTTON_STATUS.RETRY);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, setInnerStatus, onSuccess, onError]);
  const CustomButton = secondary ? Button.Secondary : Button.Primary;
  switch (innerStatus) {
    case BUTTON_STATUS.PRISTINE:
      return (
        <CustomButton
          text={title}
          onClick={onClick}
          className={classnames(styles.default, className)}
          {...buttonProps}
        />
      );
    case BUTTON_STATUS.PENDING:
      return (
        <Button.Primary
          leftIcon={<Loader heightInRem={2} className={styles.loader} />}
          className={classnames(styles.default, styles.pending, className)}
          {...buttonProps}
        />
      );
    case BUTTON_STATUS.FULFILLED:
      return (
        <Button.Primary
          leftIcon={<WhiteCheck />}
          className={classnames(styles.default, styles.success, className)}
          {...buttonProps}
        />
      );
    case BUTTON_STATUS.REJECTED:
      return (
        <Button.Primary
          leftIcon={<WhiteCross />}
          className={classnames(styles.default, styles.error, className)}
          {...buttonProps}
        />
      );
    case BUTTON_STATUS.RETRY:
      return (
        <CustomButton
          text={errorTitle ?? title}
          onClick={onClick}
          className={classnames(styles.default, className)}
          {...buttonProps}
        />
      );
    default:
      return null;
  }
};

export default FetchButton;
