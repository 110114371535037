import classNames from 'classnames';
import React from 'react';

import styles from './AppButton.module.scss';
import { Props } from './AppButton.types';

const AppButton = ({ icon, iconStyle, name, category, description, link, onClick }: Props) => {
  return (
    <div
      className={styles.container}
      onClick={() => {
        onClick?.(name, link);
        window.open(link, '_blank', 'noopener,noreferrer');
      }}
    >
      <div className={classNames(iconStyle, styles.iconContainer)}>{icon}</div>
      <div className={styles.text}>
        <div className={styles.category}>{category}</div>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default AppButton;
