import classnames from 'classnames';
import React from 'react';

import styles from './WithLightTitle.module.scss';
import { Props } from './WithLightTitle.types';

const WithLightTitle = ({
  title,
  textAlign,
  children,
  className,
  titleClassName,
  rightTitleComponent,
}: Props) => (
  <div className={className}>
    {rightTitleComponent ? (
      <div className={styles.titleWithRightComponent}>
        <div
          className={classnames(styles.title, titleClassName)}
          style={{ textAlign: textAlign ?? 'left' }}
        >
          {title}
        </div>
        {rightTitleComponent}
      </div>
    ) : (
      <div
        className={classnames(styles.title, titleClassName)}
        style={{ textAlign: textAlign ?? 'left' }}
      >
        {title}
      </div>
    )}
    {children}
  </div>
);

export default WithLightTitle;
