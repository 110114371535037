import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import WithLightTitle from '../../WithLightTitle';
import DragDropListItem from '../DragDropListItem/DragDropListItem.component';

import styles from './DragDropListMultiSections.module.scss';
import { Props } from './DragDropListMultiSections.types';

const DragDropListMultiSections = ({
  itemsBySection,
  emptySectionPlaceholder,
  draggableItemClassname,
  droppableZoneClassname,
  onDragEnd,
}: Props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {itemsBySection.map(itemsInSection => (
        <WithLightTitle
          key={itemsInSection.section}
          title={itemsInSection.sectionLabel ?? itemsInSection.section}
          className={styles.lightTitle}
        >
          <Droppable droppableId={itemsInSection.section}>
            {provided => (
              <ul
                className={droppableZoneClassname}
                key={itemsInSection.section}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemsInSection.items.map((listItem, idx) => {
                  return (
                    <DragDropListItem
                      key={listItem.id}
                      item={listItem}
                      index={idx}
                      draggableItemClassname={draggableItemClassname}
                    />
                  );
                })}
                {itemsInSection.items.length === 0 && emptySectionPlaceholder && (
                  <>{emptySectionPlaceholder}</>
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </WithLightTitle>
      ))}
    </DragDropContext>
  );
};

export default DragDropListMultiSections;
