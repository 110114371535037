import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import styles from './ToggleCardGroup.module.scss';
import { Props } from './ToggleCardGroup.types';

const ToggleCardGroup = ({ items, onChange, value, className, exclusive }: Props) => {
  return (
    <ToggleButtonGroup
      onChange={onChange}
      className={classnames(styles.container, className)}
      value={value}
      exclusive={exclusive}
    >
      {items.map(item => (
        <ToggleButton
          disableRipple
          key={item.value}
          value={item.value}
          className={styles.toggleButton}
        >
          <div className={styles.buttonContainer}>
            {item.icon && <div className={styles.iconPadding}>{item.icon}</div>}
            <div>{item.label}</div>
          </div>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleCardGroup;
