import * as moment from 'moment';
import React from 'react';

import { TextInput } from '../TextInput';

import styles from './DurationPicker.module.scss';
import { Props } from './DurationPicker.types';

const DurationPicker = ({ duration, onChange, containersClassName }: Props) => (
  <div className={styles.durationInputLine}>
    <TextInput
      type="number"
      className={styles.durationInput}
      containerClassName={containersClassName}
      value={Math.floor(duration.asHours()).toString()}
      onChange={event => {
        const value = (event.target as HTMLInputElement).value;
        onChange(moment.duration({ hours: Number(value), minutes: duration.minutes() }));
      }}
    />
    h
    <TextInput
      type="number"
      className={styles.durationInput}
      containerClassName={containersClassName}
      value={duration.minutes().toString()}
      onChange={event => {
        const value = (event.target as HTMLInputElement).value;
        if (Number(value) < 60) {
          onChange(
            moment.duration({ hours: Math.floor(duration.asHours()), minutes: Number(value) })
          );
        }
      }}
    />
    min
  </div>
);

export default DurationPicker;
