import { InfoOutline } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { Props } from 'Message/Base/Base.types';
import React from 'react';

import Base from '../Base';

import styles from './Info.module.scss';

const Info = ({ children, className }: Props) => (
  <Base icon={<InfoOutline />} className={classnames(styles.info, className)}>
    {children}
  </Base>
);

export default Info;
