import React from 'react';

import { BaseProps } from './Base.types';

const Base = ({ children, disabled, onClick, ...otherProps }: BaseProps) => {
  return (
    <button disabled={disabled} onClick={disabled ? undefined : onClick} {...otherProps}>
      {children}
    </button>
  );
};

export default Base;
