export enum SIGNATURE_MODE {
  SIGN = 'SIGN',
  TYPE = 'TYPE',
  DOWNLOAD = 'DOWNLOAD',
}

export enum SIGNATURE_MODE_DESKTOP {
  SIGN = 'signature manuelle',
  TYPE = 'saisie texte',
  DOWNLOAD = 'téléchargement',
}

export enum SIGNATURE_MODE_MOBILE {
  SIGN = 'manuelle',
  TYPE = 'texte',
  DOWNLOAD = 'téléchargement',
}

export interface Props {
  onSignChange: (image?: string) => void;
  onClear: () => void;
  size?: {
    width?: number;
    height?: number;
  };
  containerClassName?: string;
  disabled?: boolean;
  isMobile?: boolean;
}
