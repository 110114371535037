import { DurationPicker } from '@randstad-lean-mobile-factory/react-components-core';
import { ComponentProps } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import * as z from 'zod';

import { FormFieldProps } from '../../Utils';

type DurationPickerProps = ComponentProps<typeof DurationPicker>;

export const durationSchema = z.object({
  hours: z.number(),
  minutes: z.number(),
});

export type Duration = z.infer<typeof durationSchema>;

export type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = FormFieldProps<Omit<DurationPickerProps, 'onChange' | 'duration'>, TFieldValues, TName>;
