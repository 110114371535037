import classnames from 'classnames';
import React from 'react';

import styles from './SidebarMenu.module.scss';
import { Props } from './SidebarMenu.types';

function SidebarMenu<T>({
  controls,
  onItemClick,
  selectedMenuValue,
  keyValueExtractor,
  containerClassname,
  itemClassname,
}: Props<T>) {
  return (
    <div className={classnames(styles.linkContainer, containerClassname)}>
      {controls.map((item, idx) => {
        const isCurrentSelectedMenu = keyValueExtractor(item).value.includes(selectedMenuValue);
        return (
          <div
            key={keyValueExtractor(item).key}
            className={classnames(styles.link, itemClassname, {
              [styles.active]: isCurrentSelectedMenu,
            })}
            onClick={() => onItemClick(item)}
          >
            <div className={styles.navNumber}>{`${idx + 1}.`}</div>
            {`${keyValueExtractor(item).key}`}
          </div>
        );
      })}
    </div>
  );
}

export default SidebarMenu;
