import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import Button from '../index';
import styles from '../ThreeDotsButton/ThreeDotsButton.module.scss';

import { Props } from './ThreeDotsButton.types';

const ThreeDotsButton = ({ onClick, triggered, className, disabled, ...buttonProps }: Props) => {
  return (
    <Button.Tertiary
      onClick={onClick}
      disabled={disabled}
      className={classnames(styles.button, { [styles.activeIcon]: triggered }, className)}
      {...buttonProps}
    >
      <ThreeDots className={styles.icon} />
    </Button.Tertiary>
  );
};

export default ThreeDotsButton;
