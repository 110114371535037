import { DangerOutline } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { Props } from 'Message/Base/Base.types';
import React from 'react';

import Base from '../Base';

import styles from './Warning.module.scss';

const Warning = ({ children, className }: Props) => (
  <Base
    icon={<DangerOutline className={styles.icon} />}
    className={classnames(styles.info, className)}
  >
    {children}
  </Base>
);

export default Warning;
