import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import Button from '../Buttons';

import styles from './HorizontalTextIconCard.module.scss';
import { Props } from './HorizontalTextIconCard.types';

const HorizontalTextIconCard = ({
  containerClassName,
  textContainerClassName,
  leftIcon,
  leftIconClassName,
  label,
  labelClassName,
  overLabel,
  subLabel,
  rightComponent,
  rightIcon,
  onRightIconClick,
  disabled,
  onClick,
  selected,
}: Props) => (
  <div
    className={classnames({ [styles.selected]: selected }, styles.container, containerClassName)}
    onClick={onClick}
  >
    <div className={styles.leftSide}>
      <div className={leftIconClassName}>{leftIcon}</div>
      <div
        className={classnames(styles.text, textContainerClassName, {
          [styles.textWithSubLabel]: subLabel,
        })}
      >
        <div className={styles.overLabel}>{overLabel}</div>
        <div className={classnames(styles.label, labelClassName)}>{label}</div>
        <div className={styles.subLabel}>{subLabel}</div>
      </div>
    </div>
    <div className={styles.rightSide} onClick={event => event.stopPropagation()}>
      {rightComponent}
      {rightIcon && (
        <Button.Tertiary
          className={styles.iconButton}
          disabled={disabled}
          onClick={onRightIconClick}
        >
          {rightIcon}
        </Button.Tertiary>
      )}
      {selected && <Check className={styles.checkIcon} />}
    </div>
  </div>
);

export default HorizontalTextIconCard;
