import classnames from 'classnames';
import React from 'react';

import styles from './TextInput.module.scss';
import { Props } from './TextInput.types';

const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    leftIcon,
    rightIcon,
    onRightIconClick,
    containerClassName,
    className,
    disabled,
    ...rest
  } = props;
  return (
    <label htmlFor={rest.key?.toString()}>
      <div
        className={classnames(styles.container, containerClassName, {
          [styles.disabled]: disabled,
        })}
      >
        {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
        <input
          ref={ref}
          {...rest}
          disabled={disabled}
          className={classnames(styles.input, className, {
            [styles.disabled]: disabled,
          })}
        />
        {rightIcon && (
          <div
            className={classnames(styles.icon, {
              [styles.clickable]: onRightIconClick !== undefined,
            })}
            onClick={onRightIconClick}
          >
            {rightIcon}
          </div>
        )}
      </div>
    </label>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
