import classnames from 'classnames';
import React from 'react';

import styles from './LetterBadge.module.scss';
import { Props } from './LetterBadge.types';

const LetterBadge = (props: Props) => (
  <div className={classnames(styles.container, props.className)}>{props.text}</div>
);

export default LetterBadge;
