import {
  TextInput as TextInputComponent,
  TextInputProps,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import styles from './TextInput.module.scss';
import { Props } from './TextInput.types';

function TextInput<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  defaultValue,
  containerClassName,
  ...textInputProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <TextInputComponent
        containerClassName={classnames(containerClassName, {
          [styles.disabled]: textInputProps.disabled,
          [styles.error]: error !== undefined,
        })}
        {...textInputProps}
        {...field}
        value={field.value as TextInputProps['value']}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default TextInput;
