import classnames from 'classnames';
import React from 'react';

import styles from './AppButton.module.scss';
import { Props } from './AppButton.types';

const AppButton = ({ name, link, icon, onClick, containerClassName }: Props) => {
  return (
    <div
      className={classnames(styles.container, containerClassName)}
      onClick={() => {
        onClick?.(name, link);
        window.open(link, '_blank', 'noopener,noreferrer');
      }}
    >
      <div>{icon}</div>
      <div>{name}</div>
    </div>
  );
};

export default AppButton;
