import {
  ToggleSwitch as ToggleSwitchComponent,
  ToggleSwitchProps,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import { Props } from './ToggleSwitch.types';

function ToggleSwitch<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  defaultValue,
  ...toggleSwitchProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <ToggleSwitchComponent
        {...toggleSwitchProps}
        checked={field.value as ToggleSwitchProps['checked']}
        onCheckStatusChange={field.onChange}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default ToggleSwitch;
