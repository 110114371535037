import React from 'react';

import styles from './Content.module.scss';
import { ContentProps } from './Content.types';

const Content = ({ leftIcon, text, rightIcon }: ContentProps) => (
  <div className={styles.container}>
    {leftIcon}
    {leftIcon && text ? <div className={styles.separator} /> : null}
    {text}
    {rightIcon && text ? <div className={styles.separator} /> : null}
    {rightIcon}
  </div>
);

export default Content;
