import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { PopupActions } from 'reactjs-popup/dist/types';

import { BaseModalProps } from './BaseModal.types';

const BaseModal = React.forwardRef<PopupActions, BaseModalProps>(
  ({ contentStyle, overlayStyle, ...rest }: BaseModalProps, ref) => (
    <div onClick={event => event.stopPropagation()}>
      <Popup
        ref={ref}
        contentStyle={{
          position: 'relative',
          outline: 'none',
          border: 'none',
          borderRadius: '8px',
          padding: 0,
          width: '100%',
          maxWidth: '38rem',
          ...contentStyle,
        }}
        overlayStyle={{
          backgroundColor: 'rgba(0,0,0,0.7)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          overflowY: 'scroll',
          paddingTop: '2.5rem',
          paddingBottom: '2.5rem',
          ...overlayStyle,
        }}
        modal
        nested
        {...rest}
      />
    </div>
  )
);

export default BaseModal;
