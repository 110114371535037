import classnames from 'classnames';
import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './SelectionIconButtonLoader.module.scss';
import { Props } from './SelectionIconButtonLoader.types';

const SelectionIconButtonLoader = ({ className }: Props) => (
  <div className={classnames(styles.container, className)}>
    <ContentLoader height="100%" width="100%" uniqueKey="selectionIconButtonCard">
      <rect x="22.5%" y="10" rx="4" ry="4" width="55%" height="70" />
      <rect x="15%" y="90" rx="4" ry="4" width="70%" height="16" />
    </ContentLoader>
  </div>
);

export default SelectionIconButtonLoader;
