import classnames from 'classnames';
import React from 'react';

import styles from './AddButton.module.scss';
import { Props } from './AddButton.types';

const AddButton = ({ text, className, disabled, icon, ...buttonProps }: Props) => {
  return (
    <button
      className={classnames(styles.container, { [styles.disabled]: disabled }, className)}
      disabled={disabled ?? false}
      {...buttonProps}
    >
      {icon}
      <p className={styles.text}>{text}</p>
    </button>
  );
};

export default AddButton;
