import React from 'react';

import styles from './RadioButtonGroup.module.scss';
import { Props } from './RadioButtonGroup.types';

const RadioButtonGroup = (props: Props) => (
  <div className={styles.container}>
    {props.title && <p className={styles.buttonTitle}>{props.title}</p>}
    {props.radioButtonsInfo.map((radioButton, idx) => (
      <label className={styles.radioButton} key={`${radioButton.id}-${radioButton.value}`}>
        <input
          type="radio"
          id={radioButton.id}
          value={radioButton.value}
          name={props.name}
          className={styles.button}
          onClick={() => props.onChange(idx, radioButton.id)}
          checked={props.selected !== undefined ? props.selected === radioButton.id : undefined}
        />
        {radioButton.label}
      </label>
    ))}
  </div>
);

export default RadioButtonGroup;
