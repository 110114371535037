import { useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import Base from '../Base';
import Content from '../Content';
import { ButtonProps, Sizeable } from '../shared';

import styles from './Primary.module.scss';

const BasePrimary = ({
  children,
  disabled,
  className,
  text,
  leftIcon,
  rightIcon,
  darkMode,
  ...props
}: ButtonProps) => {
  const isMobile = useMediaQuery('(max-device-width: 768px)');
  return (
    <Base
      className={classnames(
        { [styles.lightModeContainerMobile]: !darkMode && isMobile },
        { [styles.lightModeContainerDesktop]: !darkMode && !isMobile },
        { [styles.disabled]: disabled },
        { [styles.darkModeContainer]: darkMode },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {leftIcon || text || rightIcon ? (
        <Content leftIcon={leftIcon} text={text} rightIcon={rightIcon} />
      ) : (
        children
      )}
    </Base>
  );
};

const Primary = Sizeable(BasePrimary);

export default Primary;
