import {
  TimePicker as TimePickerComponent,
  TimePickerProps,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage';

import styles from './TimePicker.module.scss';
import { Props } from './TimePicker.types';

function TimePicker<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  defaultValue,
  className,
  ...timePickerProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div>
      <TimePickerComponent
        className={classnames(className, {
          [styles.disabled]: timePickerProps.disabled,
          [styles.error]: error !== undefined,
        })}
        {...timePickerProps}
        {...field}
        value={field.value as TimePickerProps['value']}
      />
      <ErrorMessage error={error} />
    </div>
  );
}

export default TimePicker;
